html {
  scroll-behavior: smooth;
  -webkit-text-size-adjust: none;
  touch-action: manipulation;
}
body {
  margin: 0;
  padding: 0;
  font-family: Paralucent, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f7f9f9;
}

code {
  font-family: Paralucent, source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
}

label,
span,
p,
input,
select {
  font-family: Paralucent, sans-serif;
}
